const appConfig = {
  oldPortalUrl:
    process.env.NEXT_PUBLIC_PUBLIC_CLOUD_URL &&
    process.env.NEXT_PUBLIC_PUBLIC_CLOUD_URL.trim().length
      ? process.env.NEXT_PUBLIC_PUBLIC_CLOUD_URL
      : "/",
  s3URLEndpoint:
    process.env.NEXT_PUBLIC_AWS_S3_ENDPOINT &&
    process.env.NEXT_PUBLIC_AWS_S3_ENDPOINT.trim().length
      ? process.env.NEXT_PUBLIC_AWS_S3_ENDPOINT
      : "",
  cephS3URLEndpoint:
    process.env.NEXT_PUBLIC_AWS_S3_CEPH_ENDPOINT &&
    process.env.NEXT_PUBLIC_AWS_S3_CEPH_ENDPOINT.trim().length
      ? process.env.NEXT_PUBLIC_AWS_S3_CEPH_ENDPOINT
      : "",
  s3Region:
    process.env.NEXT_PUBLIC_AWS_S3_REGION &&
    process.env.NEXT_PUBLIC_AWS_S3_REGION.trim().length
      ? process.env.NEXT_PUBLIC_AWS_S3_REGION.trim()
      : "",
  trustedSource: process.env.NEXT_PUBLIC_FRONTEND_BASEURL
    ? process.env.NEXT_PUBLIC_FRONTEND_BASEURL
    : "http://localhost:3000",
  cryptoHexaDecimalString: process.env.CRYPTO_HEX_STRING
    ? process.env.CRYPTO_HEX_STRING
    : "",
  cryptoSalt: process.env.CRYPTO_SALT ? process.env.CRYPTO_SALT : "",
  cryptoBytes: process.env.CRYPTO_BYTES ? process.env.CRYPTO_BYTES : "",
  cryptoAES: process.env.CRYPTO_AES ? process.env.CRYPTO_AES : "",
  cryptoPassword: process.env.CRYPTO_PASSWORD
    ? process.env.CRYPTO_PASSWORD
    : "",
  basePath:
    process.env.NEXT_PUBLIC_BASEPATH &&
    process.env.NEXT_PUBLIC_BASEPATH.trim().length
      ? process.env.NEXT_PUBLIC_BASEPATH
      : "",
  logoPath:
    process.env.NEXT_PUBLIC_LOGOPATH &&
    process.env.NEXT_PUBLIC_LOGOPATH.trim().length
      ? process.env.NEXT_PUBLIC_LOGOPATH
      : "/images/publicCloudLogo.svg",
  figmaLayoutSwitcher: process.env.NEXT_PUBLIC_FIGMA_LAYOUT || "on",
  metaTitle:
    process.env.NEXT_PUBLIC_META_TITLE &&
    process.env.NEXT_PUBLIC_META_TITLE.trim().length
      ? process.env.NEXT_PUBLIC_META_TITLE
      : "Public Cloud",
  favIconPath:
    process.env.NEXT_PUBLIC_FAVICON_PATH &&
    process.env.NEXT_PUBLIC_FAVICON_PATH.trim().length
      ? process.env.NEXT_PUBLIC_FAVICON_PATH
      : "/images/favicon.ico",
  showBillingConsumption:
    process.env.NEXT_PUBLIC_BILLING_CONSUMPTION &&
    process.env.NEXT_PUBLIC_BILLING_CONSUMPTION.trim().toLowerCase() === "on"
      ? true
      : false,
  showS3Storage:
    process.env.NEXT_PUBLIC_S3_STORAGE &&
    process.env.NEXT_PUBLIC_S3_STORAGE.trim().toLowerCase() === "on"
      ? true
      : false,
  loginPagePath:
    process.env.NEXT_PUBLIC_LOGIN_PAGE &&
    process.env.NEXT_PUBLIC_LOGIN_PAGE.trim().length
      ? process.env.NEXT_PUBLIC_LOGIN_PAGE
      : "",
  RegisterPagePath:
    process.env.NEXT_PUBLIC_REGISTER_PAGE &&
    process.env.NEXT_PUBLIC_REGISTER_PAGE.trim().length
      ? process.env.NEXT_PUBLIC_REGISTER_PAGE
      : "",
  maxFileSizeCeph:
    process?.env?.NEXT_PUBLIC_MAX_FILE_SIZE_CEPH &&
    process?.env?.NEXT_PUBLIC_MAX_FILE_SIZE_CEPH?.trim()?.length
      ? process?.env?.NEXT_PUBLIC_MAX_FILE_SIZE_CEPH
      : "1000000000",
  maxFileSizeActiveScale:
    process?.env?.NEXT_PUBLIC_MAX_FILE_SIZE_ACTIVE_SCALE &&
    process?.env?.NEXT_PUBLIC_MAX_FILE_SIZE_ACTIVE_SCALE?.trim()?.length
      ? process?.env?.NEXT_PUBLIC_MAX_FILE_SIZE_ACTIVE_SCALE
      : "1073741824",
  maxFileCount: process.env.NEXT_PUBLIC_MAX_FILE_COUNT
    ? process.env.NEXT_PUBLIC_MAX_FILE_COUNT
    : 1,
  allowedFileTypes: process.env.NEXT_PUBLIC_ALLOWED_FILE_TYPES
    ? process.env.NEXT_PUBLIC_ALLOWED_FILE_TYPES
    : [],
  frontendBaseURL:
    process.env.NEXT_PUBLIC_FRONTEND_BASEURL &&
    process.env.NEXT_PUBLIC_FRONTEND_BASEURL.trim().length
      ? process.env.NEXT_PUBLIC_FRONTEND_BASEURL
      : "http://localhost:3000",
  S3ExpirationTime:
    process.env.AWS_S3_URL_EXPIRATION_TIME &&
    process.env.AWS_S3_URL_EXPIRATION_TIME.trim().length
      ? process.env.AWS_S3_URL_EXPIRATION_TIME.trim()
      : "3600",
  horizonPortalUrl:
    process.env.NEXT_PUBLIC_HORIZON_PORTAL &&
    process.env.NEXT_PUBLIC_HORIZON_PORTAL.trim().length
      ? process.env.NEXT_PUBLIC_HORIZON_PORTAL
      : "https://console.openstack.acecloudhosting.com",
  knowledgeBaseUrl:
    process.env.NEXT_PUBLIC_KNOWLEDGE_BASE_URL &&
    process.env.NEXT_PUBLIC_KNOWLEDGE_BASE_URL.trim().length
      ? process.env.NEXT_PUBLIC_KNOWLEDGE_BASE_URL
      : "https://docs.acecloudhosting.com/knowledge-base",
  termsAndConditionsUrl:
    process.env.NEXT_PUBLIC_TERMS_AND_CONDITION_URL &&
    process.env.NEXT_PUBLIC_TERMS_AND_CONDITION_URL.trim().length
      ? process.env.NEXT_PUBLIC_TERMS_AND_CONDITION_URL
      : "https://acecloud.ai/resources/legal/terms-of-use-policy/",
  privacyPolicyUrl:
    process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL &&
    process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL.trim().length
      ? process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL
      : "https://acecloud.ai/resources/legal/privacy-policy/",
  supportUrl:
    process.env.NEXT_PUBLIC_GET_SUPPORT &&
    process.env.NEXT_PUBLIC_GET_SUPPORT.trim().length
      ? process.env.NEXT_PUBLIC_GET_SUPPORT
      : "https://docs.acecloudhosting.com/knowledge-base/support",
  isProduction:
    process.env.NEXT_PUBLIC_IS_PRODUCTION &&
    process.env.NEXT_PUBLIC_IS_PRODUCTION.trim().length
      ? process.env.NEXT_PUBLIC_IS_PRODUCTION
      : "",
  stagingUsernamePrefix:
    process.env.NEXT_PUBLIC_OPENSTACK_STAGING_USERNAME_PREFIX &&
    process.env.NEXT_PUBLIC_OPENSTACK_STAGING_USERNAME_PREFIX.trim().length
      ? process.env.NEXT_PUBLIC_OPENSTACK_STAGING_USERNAME_PREFIX
      : "",
  productionUsernamePrefix:
    process.env.NEXT_PUBLIC_OPENSTACK_PRODUCTION_USERNAME_PREFIX &&
    process.env.NEXT_PUBLIC_OPENSTACK_PRODUCTION_USERNAME_PREFIX.trim().length
      ? process.env.NEXT_PUBLIC_OPENSTACK_PRODUCTION_USERNAME_PREFIX
      : "",
  showDelhiRegion:
    process.env.NEXT_PUBLIC_SHOW_DELHI_REGION &&
    process.env.NEXT_PUBLIC_SHOW_DELHI_REGION.trim() &&
    process.env.NEXT_PUBLIC_SHOW_DELHI_REGION.trim().toLowerCase() === "on"
      ? true
      : false,
  showDashboard:
    process.env.NEXT_PUBLIC_SHOW_DASHBOARD &&
    process.env.NEXT_PUBLIC_SHOW_DASHBOARD.trim() &&
    process.env.NEXT_PUBLIC_SHOW_DASHBOARD.trim().toLowerCase() === "on"
      ? true
      : true,
  multipartUploadMinimumSize:
    process.env.NEXT_PUBLIC_MULTIPART_MINIMUM_SIZE?.trim().length
      ? process.env.NEXT_PUBLIC_MULTIPART_MINIMUM_SIZE
      : "104857600",
  s3RetryLimit: process.env.NEXT_PUBLIC_S3_RETRY_LIMIT?.trim()?.length
    ? parseInt(process.env.NEXT_PUBLIC_S3_RETRY_LIMIT)
    : 5,
  s3MaximumParallelUploadLimit:
    process.env.NEXT_PUBLIC_S3_MAXIMUM_PARALLEL_UPLOADS?.trim()?.length
      ? parseInt(process.env.NEXT_PUBLIC_S3_MAXIMUM_PARALLEL_UPLOADS)
      : 5,
  s3XMLLink: process.env.NEXT_PUBLIC_S3_XML_LINK?.trim()?.length
    ? process.env.NEXT_PUBLIC_S3_XML_LINK
    : "",
  s3XMLGranteeLink: process.env.NEXT_PUBLIC_S3_XML_GRANTEE_LINK?.trim()?.length
    ? process.env.NEXT_PUBLIC_S3_XML_GRANTEE_LINK
    : "",
  s3XMLAuthenticatedUserGroupTypeLink:
    process.env.NEXT_PUBLIC_S3_XML_AUTHENTICATED_USER_LINK?.trim().length
      ? process.env.NEXT_PUBLIC_S3_XML_AUTHENTICATED_USER_LINK
      : "",
  s3XMLAllUsersGroupTypeLink:
    process.env.NEXT_PUBLIC_S3_XML_ALL_USERS_LINK?.trim()?.length
      ? process.env.NEXT_PUBLIC_S3_XML_ALL_USERS_LINK
      : "",
  s3ChunkSize: process.env.NEXT_PUBLIC_S3_MAX_CHUNK_SIZE?.trim()?.length
    ? process.env.NEXT_PUBLIC_S3_MAX_CHUNK_SIZE
    : "104857600",
  noidaHorizonPortalUrl: process.env.NEXT_PUBLIC_NOIDA_HORIZON_PORTAL?.trim()
    ?.length
    ? process.env.NEXT_PUBLIC_NOIDA_HORIZON_PORTAL
    : "https://console-noi.acecloud.ai/dashboard/",
  razorPayKey: process.env.NEXT_PUBLIC_RAZORPAY_SECRET_KEY?.trim()?.length
    ? process.env.NEXT_PUBLIC_RAZORPAY_SECRET
    : "",
  razorPayKeyId: process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID?.trim()?.length
    ? process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID
    : "",
  stripePublicKey: process.env.NEXT_PUBLIC_STRIPE_KEY?.trim()?.length
    ? process.env.NEXT_PUBLIC_STRIPE_KEY
    : "",
  stripeSecretKey: process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY?.trim()?.length
    ? process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY
    : "",
  sumSubSdkCustomizationName:
    process.env.NEXT_PUBLIC_SUMSUB_SDK_CUSTOMIZATION_NAME?.trim()?.length
      ? process.env.NEXT_PUBLIC_SUMSUB_SDK_CUSTOMIZATION_NAME
      : "",
  monitoringRefetchInterval: process.env.MONITORING_REFETCH_INTERVAL?.trim()
    ?.length
    ? parseInt(process.env.MONITORING_REFETCH_INTERVAL)
    : 300000,
  monitoringDiskTimeout: process.env.MONITORING_DISK_TIMEOUT?.trim()?.length
    ? parseInt(process.env.MONITORING_DISK_TIMEOUT)
    : 60000,
  alertRefetchInterval: process.env.NEXT_PUBLIC_ALERT_REFETCH_INTERVAL?.trim()
    ?.length
    ? parseInt(process.env.NEXT_PUBLIC_ALERT_REFETCH_INTERVAL)
    : 100000,
};

export const TRUSTED_SOURCE = process.env.NEXT_PUBLIC_FRONTEND_BASEURL
  ? process.env.NEXT_PUBLIC_FRONTEND_BASEURL
  : "http://localhost:3000";

export default appConfig;
