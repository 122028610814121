import { toast } from "react-toastify";
import { DropEvent, FileRejection } from "react-dropzone";

import {
  useState,
  useCallback,
  createContext,
  ReactNode,
  useMemo,
} from "react";

import appConfig from "src/configs/app";
import { getMemoryString, getParsedInputTypes } from "src/configs/helper";
import { defaultRegion, fileFolderRegex } from "src/configs/constants";
import type {
  SelectedFiles,
  S3FileUploadProviderType,
  FileStats,
} from "src/types/S3/S3FileUpload";
import { S3ObjectStorageMessages } from "src/language/en/objectStorage/S3.en";
import { useRouter } from "next/router";
import { getS3ServiceProviderConfig } from "src/utils/S3/S3Helper";
import { regionProviderMapping } from "src/utils/S3/S3Constants";

const defaultProvider: S3FileUploadProviderType = {
  onFileDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {},
  onRemoveFile: (fileName: string) => {},
  onRemoveAllFiles: () => {},
  uploadStarted: false,
  setUploadStarted: () => {},
  filesToUpload: [],
  setFilesToUpload: () => {},
  fileUploadStats: [],
  setFileUploadStats: () => {},
  fileToBeAborted: "",
  setFileToBeAborted: () => {},
};

export const S3FileUploadContext =
  createContext<S3FileUploadProviderType>(defaultProvider);

interface Props {
  children: ReactNode;
}

const withRegionCheck = (WrappedComponent: React.FC<Props>, allowedRegions: string[]) => {
  const WithRegionCheck = (props: Props) => {
    const {children} = props;
    const router = useRouter();
    const region = (router.query.region as string);

    if (!region || !allowedRegions.includes(region)) {
      return children;
    }

    return <WrappedComponent {...props} />;
  };
  
  WithRegionCheck.displayName = `WithRegionCheck(${WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'})`;
  
  return WithRegionCheck;
};

const S3FileUploadProvider: React.FC<Props> = ({ children }) => {
  const [uploadStarted, setUploadStarted] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<SelectedFiles[]>([]);
  const [fileUploadStats, setFileUploadStats] = useState<FileStats[]>([]);
  const [fileToBeAborted, setFileToBeAborted] = useState<string>("");
  const router = useRouter();
  const region = (router.query.region as string) ?? defaultRegion;
  const s3ServiceConfig = getS3ServiceProviderConfig(region);

  const validateFile = (filesList: File[]): SelectedFiles[] => {
    const maxSize = s3ServiceConfig.maxFileSize;
    let updatedFiles: SelectedFiles[] = [];
    const inputTypes = getParsedInputTypes();
    filesList.forEach((file: File) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if ( 
        !inputTypes ||
        !Array.isArray(inputTypes) ||
        !inputTypes.length ||
        !fileExtension ||
        !inputTypes.some((str: string) =>
          fileExtension.includes(str.toLowerCase()),
        )
      ) {
        toast.error(S3ObjectStorageMessages.fileTypeNotAllowed);
        return;
      }

      if (!fileFolderRegex.test(file.name)) {
        toast.error(S3ObjectStorageMessages.invalidFileNameMessage);
      } else if (file.size > maxSize) {
        toast.error(
          `File size should not be greater than ${getMemoryString(maxSize, "B")}`,
        );
      } else if (file.size === 0) {
        toast.error(S3ObjectStorageMessages.zeroFileSizeErrorMessage);
      } else {
        const newFile: SelectedFiles = {
          file: file,
          id: file.name,
          isUploading: false,
          isAborting: false,
          isAborted: false,
          isCompleted: false,
        };
        updatedFiles.push(newFile);
      }
    });
    return updatedFiles;
  };

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent,
    ) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        if (
          acceptedFiles.length + filesToUpload.length >
          +appConfig.maxFileCount
        ) {
          toast.error(
            "Can't upload more than " + appConfig.maxFileCount + " files",
          );
          return;
        }

        const filesList = Array.from(acceptedFiles);
        let updatedFiles = validateFile(filesList);
        setFilesToUpload((files) => [...files, ...updatedFiles]);
      }
    },
    [filesToUpload],
  );

  const onRemoveFile = useCallback(
    (fileName: string) => {
      const updatedFiles = filesToUpload.filter((file) => file.id !== fileName);
      setFilesToUpload(updatedFiles);
    },
    [filesToUpload],
  );

  const onRemoveAllFiles = useCallback(() => {
    setFilesToUpload([]);
  }, []);

  const contextValue = useMemo(
    () => ({
      onFileDrop: onDrop,
      onRemoveFile,
      onRemoveAllFiles,
      uploadStarted,
      setUploadStarted,
      filesToUpload,
      setFilesToUpload,
      fileUploadStats,
      setFileUploadStats,
      fileToBeAborted,
      setFileToBeAborted,
    }),
    [
      onDrop,
      onRemoveFile,
      onRemoveAllFiles,
      uploadStarted,
      setUploadStarted,
      filesToUpload,
      setFilesToUpload,
      fileUploadStats,
      setFileUploadStats,
      fileToBeAborted,
      setFileToBeAborted,
    ],
  );

  return (
    <S3FileUploadContext.Provider value={contextValue}>
      {children}
    </S3FileUploadContext.Provider>
  );
};

const allowedRegions = Object.keys(regionProviderMapping) ?? [];

const S3FileUploadProviderWithRegionCheck = withRegionCheck(S3FileUploadProvider, allowedRegions);

export default S3FileUploadProviderWithRegionCheck;
