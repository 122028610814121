import appConfig from "src/configs/app";
import { ServiceProviderConfigType } from "src/types/S3/service-provider-config";

export const BUCKET_ALREADY_EXISTS_ACROSS_PROJECTS =
  "The requested bucket name is not available. The bucket namespace is shared by all users of the system. Please select a different name and try again.";
export const BUCKET_ALREADY_EXISTS_IN_PROJECT =
  "Your previous request to create the named bucket succeeded and you already own it.";

export const OBJECT_LOCK_CONFIGURATION_NOT_FOUND_CODE =
  "ObjectLockConfigurationNotFoundError";

export const NO_BUCKET_POLICY_CODE = "NoSuchBucketPolicy";

export const regionProviderMapping: { [key: string]: string } = {
  "ap-south-mum-1": "ceph",
  "ap-south-noi-1": "activescale",
};

export const cephS3ServiceConfig: ServiceProviderConfigType = {
  endpoint: appConfig.cephS3URLEndpoint,
  region: appConfig.s3Region,
  maxFileSize: +appConfig.maxFileSizeCeph,
  bucketCreationAllowedRegions: [
    {
      name: "Asia Pacific (Mumbai)",
      identifier: "ap-south-mum-1",
      displayName: "Atlanta",
      service: "ceph",
    },
  ],
  defaultBucketCreationRegion: "ap-south-mum-1",
  forcePathStyle: true,
};

export const activeScaleS3ServiceConfig: ServiceProviderConfigType = {
  endpoint: appConfig.s3URLEndpoint,
  region: appConfig.s3Region,
  maxFileSize: +appConfig.maxFileSizeActiveScale,
  bucketCreationAllowedRegions: [
    {
      name: "Asia Pacific (Noida)",
      identifier: "ap-south-noi-1",
      displayName: "Noida",
      service: "activescale",
    },
  ],
  defaultBucketCreationRegion: "ap-south-noi-1",
  forcePathStyle: true,
};

export const XMLLink = appConfig.s3XMLLink;

export const aclConstants = {
  granteeLink: appConfig.s3XMLGranteeLink,
  authenticatedUserGroupTypeLink: appConfig.s3XMLAuthenticatedUserGroupTypeLink,
  allUsersGroupTypeLink: appConfig.s3XMLAllUsersGroupTypeLink,
};

export const allowedPolicyActions = [
  "s3:AbortMultipartUpload",
  "s3:CreateBucket",
  "s3:DeleteBucketPolicy",
  "s3:DeleteBucket",
  "s3:DeleteBucketWebsite",
  "s3:DeleteObject",
  "s3:DeleteObjectVersion",
  "s3:DeleteReplicationConfiguration",
  "s3:GetAccelerateConfiguration",
  "s3:GetBucketAcl",
  "s3:GetBucketCORS",
  "s3:GetBucketLocation",
  "s3:GetBucketLogging",
  "s3:GetBucketNotification",
  "s3:GetBucketPolicy",
  "s3:GetBucketRequestPayment",
  "s3:GetBucketTagging",
  "s3:GetBucketVersioning",
  "s3:GetBucketWebsite",
  "s3:GetLifecycleConfiguration",
  "s3:GetObjectAcl",
  "s3:GetObject",
  "s3:GetObjectTorrent",
  "s3:GetObjectVersionAcl",
  "s3:GetObjectVersion",
  "s3:GetObjectVersionTorrent",
  "s3:GetReplicationConfiguration",
  "s3:IPAddress",
  "s3:NotIpAddress",
  "s3:ListAllMyBuckets",
  "s3:ListBucketMultipartUploads",
  "s3:ListBucket",
  "s3:ListBucketVersions",
  "s3:ListMultipartUploadParts",
  "s3:PutAccelerateConfiguration",
  "s3:PutBucketAcl",
  "s3:PutBucketCORS",
  "s3:PutBucketLogging",
  "s3:PutBucketNotification",
  "s3:PutBucketPolicy",
  "s3:PutBucketRequestPayment",
  "s3:PutBucketTagging",
  "s3:PutBucketVersioning",
  "s3:PutBucketWebsite",
  "s3:PutLifecycleConfiguration",
  "s3:PutObjectAcl",
  "s3:PutObject",
  "s3:PutObjectVersionAcl",
  "s3:PutReplicationConfiguration",
  "s3:RestoreObject",
];

export const allowedPolicyConditions = [
  "StringEquals",
  "StringNotEquals",
  "StringEqualsIgnoreCase",
  "StringNotEqualsIgnoreCase",
  "StringLike",
  "StringNotLike",
  "NumericEquals",
  "NumericNotEquals",
  "NumericLessThan",
  "NumericLessThanEquals",
  "NumericGreaterThan",
  "NumericGreaterThanEquals",
  "DateEquals",
  "DateNotEquals",
  "DateLessThan",
  "DateLessThanEquals",
  "DateGreaterThan",
  "DateGreaterThanEquals",
  "Bool",
  "BinaryEquals",
  "IpAddress",
  "NotIpAddress",
  "ArnEquals",
  "ArnNotEquals",
  "ArnLike",
  "ArnNotLike",
  "Null",
];

export const allowedPolicyConditionKeys = [
  "aws:CurrentTime",
  "aws:EpochTime",
  "aws:PrincipalType",
  "aws:Referer",
  "aws:SecureTransport",
  "aws:SourceIp",
  "aws:UserAgent",
  "aws:username",
];
export const maxKeys = 300;

export const allowedResourceServices: { [key: string]: string } = { s3: "s3" };
export const allowedResourceTypes: { [key: string]: string } = {
  "All Resources": "All Resources",
  object: "object",
};
