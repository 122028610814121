import router from "next/router";
import apiConfig from "src/configs/api";
import {
  noidaServiceRegion,
  allowedInsightsRegions,
  atlantaServiceRegion
} from "src/configs/constants";
import { PolicyType } from "src/context/types";
import { regionProviderMapping } from "src/utils/S3/S3Constants";

const getACLSubjects = (
  currentService: string,
  currentRegion: string,
  policies: PolicyType[] | null,
): string[] => {
  let ALLOWED_COMMON_ACL_SUBJECTS: string[] = [
    "home",
    "select-region",
    "dashboard"
  ];
  const queryParams = router.query;
  const currentProjectId = queryParams[apiConfig.projectId] as string;

  ALLOWED_COMMON_ACL_SUBJECTS = Array.from(
    new Set(ALLOWED_COMMON_ACL_SUBJECTS),
  );

  let aclSubjects = [...ALLOWED_COMMON_ACL_SUBJECTS];

  if (currentService === "e2e") {
    aclSubjects.push(
      "dashboard",
      "instances",
      "key-pairs",
      "volumes",
      "floating-ips",
      "security-groups",
      "vpc",
      "order-project",
      "kyc",
    );
  } else {
    if (Array.isArray(policies)) {
      policies.forEach((policy) => {
        policy?.commonServices?.forEach((service) => {
          if (!service?.name || !service?.access) return;
          switch (service.name) {
            case "IAM":
              aclSubjects.push("iam-policy", "iam-user", "iam-group");
              break;
            case "Billing":
              aclSubjects.push("billing-overview", "billing-summary");
              break;
            case "Logs":
              aclSubjects.push("logs", "activity-logs");
              break;
            case "Order New Project":
              aclSubjects.push("order-project", "kyc");
              break;
            case "Tickets" :
              aclSubjects.push("zoho-dashboard", "zoho-tickets");
              break;
            default:
              break;
          }
        });
        const matchedProject = policy.projects?.find(
          (project) => project.id === currentProjectId,
        );
        if (!matchedProject) return;
        const matchedRegion = matchedProject.regions?.find(
          (region) => region.name === currentRegion,
        );
        if (!matchedRegion) return;
        matchedRegion.services?.forEach((service) => {
          if (!service?.name || !service.access) return;
          switch (service.name) {
            case "Compute":
              aclSubjects.push("instances", "spot-instances", "templates", "deployments");
              break;
            case "Storage":
              aclSubjects.push("snapshots", "images", "volumes", "backup");
              break;
            case "S3 Storage":
              if (Object.keys(regionProviderMapping).includes(currentRegion)) {
                aclSubjects.push("s3-buckets", "s3-users", "s3-access-keys");
              }
              break;
            case "Networks":
              aclSubjects.push(
                "load-balancers",
                "vpc",
                "ports",
                "subnets",
                "routers",
                "security-groups",
                "floating-ips",
              );
              if (currentRegion !== noidaServiceRegion) {
                aclSubjects.push("vpns");
              }
              break;
            case "Kubernetes":
              aclSubjects.push(
                "k8s-overview",
                "k8sSecurityGroups",
                "k8sLoadBalancer",
                "k8sVolumes",
                "k8sSnapshots",
              );
              break;
            case "Databases": {
              if (currentRegion !== atlantaServiceRegion) {
                aclSubjects.push("dbaas");
              }
              break;
            }
            case "Utilities":
              aclSubjects.push(
                "users",
                "key-pairs",
                "horizon-portal",
                "backup-centers",
                "backup-centers-dashboard",
                "backup-centers-policies",
                "backup-centers-notifications",
                "backup-centers-configure",
                "backup-centers-tasks",
              );
              break;
            case "Enable-Region":
              if (currentService === "") {
                aclSubjects.push("get-started");
              }
              break;
            case "Insights":
              if (allowedInsightsRegions.includes(currentRegion)) {
                aclSubjects.push(
                  "insights",
                  "insights-alert",
                  "insights-notifications",
                );
              }
              break;
            default:
              break;
          }
        });
      });
    }
  }
  return aclSubjects;
};

export default getACLSubjects;
