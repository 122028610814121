var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2KRhLEqEDrdWi1c18xxHi"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/app";

// This file configures the initialization of Sentry on the client. The config you add here will be used whenever a users loads a page in their browser.
import * as Sentry from "@sentry/nextjs";

const sentryDsn =
  process.env.NODE_ENV && process.env.NODE_ENV === "production"
    ? process.env.NEXT_PUBLIC_SENTRY_DSN_PRODUCTION
    : process.env.NEXT_PUBLIC_SENTRY_DSN_DEVELOPMENT;

Sentry.init({
  dsn: sentryDsn,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
