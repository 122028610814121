import appConfig from "src/configs/app";

const apiConfig = {
  volumeList: "admin/os/cinder/236b4ea789944f149787189f8a4963d1/volumes/detail",
  subnet: `/cloud/vpcs/subnets`,
  vpcPort: `/os/neutron/ports`,
  storageTokenKeyName: "accessToken",
  routers: "/os/neutron/routers",
  interfaces: "/os/neutron/interfaces",
  interfacesDetail: "/detail",
  router: "/router",
  staticRoutes: "/static-routes",
  deleteStaticRoutes: "/static-routes/remove_extraroutes",
  vpc: `/cloud/vpcs`,
  currentRegion: "region",
  whmcsCookie: "WHMCSFR44VD4mpvF4",
  userRemember: "userremember",
  hostingId: "id",
  projectId: "project_id",
  timezone: "timezone",
  loginEndpoint: "/auth/login",
  proxyLoginEndpoint: `${appConfig.basePath}/api/auth/login`,
  // proxyLoginEndpoint: "/auth/login",
  sendSms: "/kyc/send-sms",
  verifySms: "/kyc/verify-sms",
  signup: "/auth/signup",
  meEndpoint: "/auth/me",
  userProjects: "/projects",
  userAllowedProjects: "/projects/allowed-projects",
  logoutEndpoint: "/auth/logout",
  storageGroupList: `https://api-us-east-at-1.openstack.acecloudhosting.com:9696/v2.0/security-groups?limit=10`,
  billingSummaryEndpoint: "/billing-usage/summary",
  billingDetailEndpoint: "/billing-usage/detailed",
  billingDetailRangeEndpoint: "/billing/detail-range",
  billingConsumptionEndpoint: "/billing-usage/consumption",
  keyPair: "/os/compute/os-keypairs",
  users: "/os/identity/users",
  s3Keys: "s3/accounts/keys",
  s3Account: "s3/accounts",
  s3Buckets: `${appConfig.basePath}/api/s3/buckets`,
  securityGroup: "/os/neutron/security-groups",
  securityGroupRules: "/os/neutron/security-group-rules",
  s3InitiateMultiPart: "/api/s3/initiate-multipart",
  s3InitiateSinglePart: "/api/s3/upload",
  s3UploadPart: `${appConfig.basePath}/api/s3/upload-part`,
  s3CompleteMultiPart: "/api/s3/complete-multipart",
  s3AbortMultiPart: "/api/s3/abort-multipart",
  s3PresignedURL: `/api/s3/generate-presigned-url`,
  snapshotsCinder: "/os/cinder/",
  snapshots: "/snapshots",
  s3AccountUsers: "/s3/accounts/users",
  keyPairs: "/cloud/key-pairs",
  floatingIp: "/cloud/floating-ips",
  floatingIpActions: "/cloud/floating-ips/action?",
  instances: "/cloud/instances",
  instanceImages: "/cloud/images",
  flavors: "os/compute/flavors",
  vpcs: "/cloud/vpcs",
  securityGroups: "/cloud/security-groups",
  instancesInterfaces: "/cloud/instances",
  instancesInterfacePort: "/cloud/instances/interface-port",
  instancesInterfacePortSecurityGroups:
    "/cloud/instances/interface-security-group",
  cinder: "/os/cinder",
  limits: "/cloud/quota/storage",
  servers: "/os/compute/servers/detail",
  networkQuotas: "/cloud/quota/network",
  computeQuotas: "/cloud/quota/compute",
  tutorials: "https://docs.acecloudhosting.com/knowledge-base/tutorials",
  knowledgebase: "https://docs.acecloudhosting.com/knowledge-base/",
  gettingStarted: "https://docs.acecloudhosting.com/knowledge-base/compute",
  images: "/os/glance/images",
  volumes: "/cloud/volumes",
  newflavors: "/cloud/flavors",
  computeQuota: "/cloud/quota/compute",
  bootalbleSnapshots: "/cloud/instances/bootable-snapshot",
  gpuFlavors: "/cloud/flavors",
  backup: "/backups",
  backupDetail: "/detail",
  backupRestore: "/restore",
  volume: "/volumes",
  vpnIke: "/cloud/vpn/ike",
  vpnIpsec: "/cloud/vpn/ipsec",
  subnetOld: `/os/neutron/subnets`,
  vpnServices: "/cloud/vpn/vpnservice",
  vpnServicesSubnet: "/cloud/vpn/vpnservice/getAttachedSubnetsToRouter",
  endpointGroup: "/cloud/vpn/endpoint-group",
  ipSecSite: "cloud/vpn/site-connection",
  projects: `/cloud/projects`,
  activityLogs: "/audit-logs",
  imageCategories: `/cloud/images/categories`,
  inviteNewUser: `/iam/users/inviteUser`,
  createUserGroupMapping: `/iam/users/createUserGroupMapping`,
  getAllGroups: `/iam/groups`,
  getUserDetails: `/iam/users/getUserDetails`,
  getUsersDetails: `/iam/users/getAllUsersDetails`,
  getPoliciesDetails: `/iam/policies/get-policies-details`,
  getGroupDetails: `/iam/groups`,
  deleteClientsUser: `/iam/users/deleteClientsUser`,
  cancelInvite: `/iam/users/cancelInvite`,
  renewInvite: `/iam/users/resentInvite`,
  projectsList: "/iam/policies/get-projects",
  iamPolicies: "/iam/policies",
  createIamPolicy: "/iam/policies/create",
  fetchIamPolicies: "/iam/policies/get-all-policies",
  deleteMultiplePolicies: "/iam/policies/delete-multiple-policies",
  getClientsUsers: "/iam/users/getClientsUsers",
  iamGroups: "/iam/groups",
  deleteMultipleGroups: "/iam/groups/delete-multiple-groups",
  aadharOTPRequest: "/kyc/send-aadhar-otp", //kyc
  verifyaadharOTP: "/kyc/verify-aadhar", //kyc
  countriesLookup: "/auth/lookup",
  kycUpdateInfo: "/kyc/update-info", //kyc
  sumsubVerify: "/kyc/sumsub-verify", //kyc
  fetchCountries: "/auth/countries",
  addProject: "/orders/add-project",
  countires: "/auth/countries",
  paymentLink: "/payment/payment-link",
  kycStatus: "/auth/user-status",
  convertToPayAsYouGoModel: "/users/update-status",
  sendEmailOTP: "/kyc/send-email",
  verifyEmailOTP: "/kyc/verify-email",
  projectPrice: "/payment/setup-fee",
  backupCenterDashboard: "cloud/backups/job",
  backupCenterNotifications: "cloud/backups/notification",
  backupCenterPolicies: "cloud/backups/policy",
  backupCenterConfigureBackup: "cloud/backups/configure",
  fetchDatabases: "/dbaas/databases",
  alerts: "/insights/alerts",
  insightsMonitoring: "/insights/monitoring",
  pricing: "cloud/pricing",
  notifications : "/insights/notifications"
};

export default apiConfig;
