import { ModalData, DialogData } from "src/types/Modal";

import appConfig from "src/configs/app";
import { Region } from "src/types/regions";
import { moduleNames as moduleNamesUtils } from "src/utils/moduleName";

export const BASEURL: string | undefined =
  process.env.NEXT_PUBLIC_BASEURL || "http://localhost:3001/";
export const RECAPTCHA_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_KEY ||
  "6Le2OX0qAAAAAHJQsFOsh2uMvGTrKxR0ZuQ46IVJ";

export const noResultHeading = "Sorry! No result found :(";
export const noResultPara =
  "Whoops.... this information is not available for a moment";
export const REGION_UNAVAILABLE = "Sorry! you don't have access to this region";
export const gridSpacing = 2;
export const KUBERNETES_BASE_URL =
  process.env.NEXT_PUBLIC_KUBERNETES_BASE_URL || "http://localhost:3001/k8s/";
export const KUBERNETES_SOCKET_URL =
  process.env.NEXT_PUBLIC_K8S_SHELL_URL || "ws://localhost:4001/";
export const K8sSystemNamespaces = [
  "cattle-system",
  "cattle-fleet-system",
  "cattle-impersonation-system",
  "kube-system",
];
export const toastPostion: string = "top-right";
export const toastDuration: number = 5000;
export const moduleNames: { [key: string]: { [key: string]: string } } = {
  router: {
    multipleCap: "Routers",
    multipleSmall: "routers",
    singleCap: "Router",
    singleSmall: "router",
  },
  vpcConstant: {
    version1: "VPC",
    version2: "VPCs",
  },
  subnetConstant: {
    version1: "Subnets",
    version2: "Subnet",
  },
  vpcPortConstant: {
    version1: "Ports",
    version2: "Port",
  },
  gateway: {
    version1: "Gateways",
    version2: "Gateway",
  },
  keyPair: {
    version1: "Key Pair",
    version2: "Key pair",
    version3: "key pair",
    version4: "key",
    version5: "Key",
  },
  users: {
    version1: "Users",
    version2: "User",
    version3: "users",
    version4: "user",
  },
  securityGroup: {
    version1: "Security Group",
    version2: "Security group",
    version3: "security group",
  },
  securityGroupRule: {
    version1: "Security group rule",
    version2: "Security group rules",
  },
  rules: {
    inbound: "Inbound Rules",
    outbound: "Outbound Rules",
  },
  snapshot: {
    version1: "Snapshot",
    version2: "Snapshots",
    version3: "snapshot",
    version4: "snapshots",
  },
  securityGroups: {
    multipleCap: "Security Groups",
    multipleSmall: "security groups",
    singleCap: "Security Group",
    singleSmall: "security group",
  },
  securityGroupRules: {
    multipleCap: "Security Group Rules",
    multipleSmall: "security group rules",
    singleCap: "Security Group Rule",
    singleSmall: "security group rule",
  },
  volume: {
    version1: "Volume",
    version2: "Volumes",
    version3: "volume",
    version4: "volumes",
  },
  backup: {
    version1: "Backup",
    version2: "Backups",
    version3: "backup",
    version4: "backups",
  },
};

export const toastMessage: { [key: string]: { [key: string]: string } } = {
  create: {
    schedule: "Scheduled creation of",
    success: "Successfully created",
    error: "Unable to create",
    error2: "Kindly retry.",
  },
  retrieve: {
    schedule: "Scheduled fetching",
    success: "Successfully fetched",
    error: "Unable to fetch",
    error2: "Kindly retry.",
  },
  update: {
    schedule: "Updating",
    success: "Successfully updated",
    error: "Unable to update",
    error2: "Kindly retry.",
  },
  delete: {
    schedule: "Scheduled deleting",
    success: "Successfully deleted",
    error: "Unable to delete",
    error2: "Kindly retry.",
  },
  backendError: { errorMessage: "Error occurred while processing the request" },
};

export const delayTime: number =
  process.env.NEXT_PUBLIC_DELAY_TIMEOUT &&
  process.env.NEXT_PUBLIC_DELAY_TIMEOUT.length &&
  !isNaN(Number(process.env.NEXT_PUBLIC_DELAY_TIMEOUT))
    ? Number(process.env.NEXT_PUBLIC_DELAY_TIMEOUT)
    : 2000;

export const modalDataArray: { [key: string]: ModalData } = {
  acceptTransfer: {
    title: "Accept Data",
    childComponent: "AcceptTransfer",
  },
  createS3User: {
    title: "Create User",
    childComponent: "UserForm",
  },
  editS3User: {
    title: "Edit User",
    childComponent: "UserForm",
  },
  createRouter: {
    title: `Create ${moduleNames.router.singleSmall}`,
    childComponent: "CreateRouter",
  },
  editRouter: {
    title: `Edit ${moduleNames.router.singleSmall}`,
    childComponent: "EditRouter",
  },
  setGateway: {
    title: `Set ${moduleNames.gateway.version2}`,
    childComponent: "SetGateway",
  },
  createKeyPair: {
    title: `Create ${moduleNames.keyPair.version1}`,
    childComponent: "KeyPairCreateModal",
  },
  importKeyPair: {
    title: `Import ${moduleNames.keyPair.version1}`,
    childComponent: "KeyPairImportModal",
  },
  createUser: {
    title: `Create ${(moduleNames.users && moduleNames.users.version2) || "User"}`,
    childComponent: "UserCreateModal",
  },
  editVolumeSnapshot: {
    title: `Edit ${(moduleNames.snapshot && moduleNames.snapshot.version1) || "Snapshot"}`,
    childComponent: "EditVolumeSnapshot",
  },
  createVolumeBackup: {
    title: `Create ${(moduleNames.backup && moduleNames.backup.version1) || "Backup"}`,
    childComponent: "CreateBackupOfSnapshot",
  },
  createL7Policy: {
    title: `Create L7 Policy`,
    childComponent: "CreateL7Policy",
  },
  editL7Policy: {
    title: `Edit Policy`,
    childComponent: "EditL7Policy",
  },
  createHealthMonitor: {
    title: `Create Health Monitor`,
    childComponent: "CreateHealthMonitor",
  },
  editHealthMonitor: {
    title: `Edit Health Monitor`,
    childComponent: "EditHealthMonitor",
  },
  editL7Rules: {
    title: `Edit Rule`,
    childComponent: "EditL7Rule",
  },
  editL7Pool: {
    title: `Edit Pool`,
    childComponent: "EditL7Pool",
  },
  createL7Rule: {
    title: `Create L7 Rule`,
    childComponent: "CreateL7Rule",
  },
  createK8sSecurityGroup: {
    title: `Create ${moduleNames.securityGroups.singleCap}`,
    childComponent: "CreateSecurityGroup",
  },
  createK8sSecurityGroupRules: {
    title: `Create ${moduleNames.securityGroupRules.singleCap}`,
    childComponent: "CreateSecurityGroupRules",
  },
  createSnapshot: {
    title: `Create Snapshot`,
    childComponent: "CreateEditSnapshot",
  },
  editSnapshot: {
    title: `Edit Snapshot`,
    childComponent: "CreateEditSnapshot",
  },

  podShell: {
    title: "",
    childComponent: "PodShell",
  },
  undeletableEntities: {
    title: "Failure Summary",
    childComponent: "UndeletableEntitiesTable",
  },

  editK8sLbListenter: {
    title: `Edit Listener`,
    childComponent: "EditListener",
  },
  createMember: {
    title: "Add/Remove Pool Members",
    childComponent: "CreateMember",
  },
  editMember: {
    title: "Update Member",
    childComponent: "EditMember",
  },
  editClusterNetwork: {
    title: `Manage Network`,
    childComponent: "EditNetwork",
  },
  createInterface: {
    title: `Create Interface`,
    childComponent: "CreateInterface",
  },
  inviteNewUserModal: {
    title: "Invite New User",
    childComponent: "InviteNewUser",
  },
  ManageAccessModal: {
    title: "Manage Access Rules",
    childComponent: "ManageAccessModal",
  },
};

export const dialogDataArray: { [key: string]: DialogData } = {
  cancelFormDialogData: {
    title: `Are You sure?`,
    text: `Want to leave? The progress that you made won't be saved.`,
    confirmField: false,
    buttonText: "Leave",
  },
  areYouSure: {
    title: "Are you sure?",
    text: " You want to add $300 credits",
    confirmField: false,
    buttonText: "Confirm",
  },
  confirmGovernanceMode: {
    title: "Confirm Governance Mode",
    text: "Objects placed in Governance Mode remain immutable until after they have reached the retain until date. After you confirm your Governance Mode choice, it is not possible to delete objects for 4 days unless a user has specific IAM permissions to alter the retention settings or delete the object.",
    confirmField: true,
    buttonText: "Confirm",
  },
  disableLifecycle: {
    title: `Are You sure? you want to Disable a Lifecycle Rule?`,
    text: "Disabling a lifecycle rule might result in the retention of existing objects.",
    confirmField: false,
    buttonText: "Disable",
  },
  deleteKeyPair: {
    title: `Delete ${moduleNames.keyPair.version1}`,
    text: `Are you sure you want to delete the ${moduleNames.keyPair.version3}(s)?`,
    confirmField: false,
    buttonText: "Delete",
  },
  deleteUser: {
    title: `Delete ${moduleNames.users.version2}`,
    text: `Are you sure you want to delete the ${moduleNames.users.version3}(s)?`,
    confirmField: false,
    buttonText: "Delete",
  },
  deleteNodeGroup: {
    title: `Are You sure?`,
    text: `You want to delete the given node group!`,
    confirmField: false,
    buttonText: "Confirm",
  },
  deleteItem: {
    title: `Are You sure?`,
    text: `You want to delete!`,
    confirmField: false,
    buttonText: "Confirm",
  },
};

// To be removed from this file
export const horizontalDetailsCardData: { [key: string]: string }[] = [
  {
    Name: "router-22",
    ID: "83bb916de3b94218a846b53bc45478c8",
    Description: "ip-172-31-20-113.ap-south-1.compute.internal",
    "Project ID": "i-08022cdb677f242a7",
    Status: "Active",
    "Admin State": "UP",
    "Availablity Zone": "Nova",
  },
  {
    "Network Name": "External_Net",
    "Network ID": "i-0007dcfc0715dfc91 (Rocket Chat)",
    "External Fixed ITs": "ip-172-31-20-113.ap-south-1.compute.internal",
  },
];

// To be removed from this file
export const verticalDetailsCardData: { [key: string]: string }[] = [
  {
    "Configuration Description":
      "Microsoft Windows Server 2022 Full Locale English AMI provided by Red Hat, Inc.",
    "Image ID": "ami-0a9c25c9f6f4f8a7a",
    "Instance Type": "ami-0a9c25c9f6f4f8a7a",
    "Security Group ID": "ami-0a9c25c9f6f4f8a7a",
    "key pair name": "ami-0a9c25c9f6f4f8a7a",
    "Storage (Volumes)": "1 volume(s) - 30 GiB",
    "Date Created": "1.2.2000",
  },
];
export const cardHeadingColor = "#000";

export const markerStyle = {
  m: 1.2,
  width: "1rem",
  height: "1rem",
  borderRadius: "50%",
};

export const regionGlobePath = "/images/globe.png";
export const publicCloudUrl = appConfig.oldPortalUrl
  ? appConfig.oldPortalUrl + "/clientarea.php"
  : "";
export const publicCloudCartUrl = appConfig.oldPortalUrl
  ? appConfig.oldPortalUrl + "/cart.php"
  : "";
export const submitTicketUrl = appConfig.oldPortalUrl
  ? appConfig.oldPortalUrl + "/submitticket.php?step=2&deptid=1"
  : "";
export const loginRoute = appConfig.oldPortalUrl
  ? appConfig.oldPortalUrl +
    "/index.php?m=ace_product_register_addon&action=login"
  : "";
export const defaultRegion = "ap-south-mum-1";
export const infiniteFetchSize = 10;
export const refetchInterval = 60000;
export const defaultChartHeight = 500;
export const billingApiTimeout = 60 * 1000;
export const chartHeightOffset = 12;
export const defaultDonutChartHeight = 65;

export const K8sRefetchInterval: number = 15000;
export const chartColors = ["#610D51", "#E61E65", "#FECE6C", "#FE836C"];
export const totalCostChartLabels = [
  "Previous Month",
  "Current Month",
  "Expected Bill",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const billingReportStartYear = 2024;

export const defaultCurrencyCode = "USD";
export const defaultCurrencyPrefix = "$";
export const defaultAmount = "0.0";
export const defaultDuration = "0.0";

export const externalNetworkIdSet = new Set<string>([
  "6135a960-6bb1-4a4e-837b-5946e1440557",
  "c92f8cb2-cf4d-4614-be27-e9ad192fc3e8",
  "92343d45-85d4-445f-a52e-a699a2202cbc",
]);
export const keyPairApiVersion = "Compute 2.35";
export const keyPairFileSize = 16384;

export const regions: Region[] = [
  // {
  //    name: 'US East (Atlanta)',
  //    identifier: 'us-east-atl-1',
  //    displayName: 'Mumbai',
  // },
  // {
  //   name: "US East (N. Virginia)",
  //   identifier: "us-east-1",
  //   displayName: "N. Virginia",
  // },
  {
    name: "US East (N. Virginia)",
    identifier: "us-east-1",
    displayName: "N. Virginia",
    service: "openstack",
  },
];

export const s3FeatureMessages = {
  versioningFeature: {
    description:
      "When versioning is enabled, you can then retrieve and restore any previous version of an object in the bucket.",
    note: "Note: Versions of objects are added to your total data storage costs.",
  },
  objectLockingFeature: {
    description:
      "Enabling Object lock will allow you to prevent objects from being overwritten or deleted for a fixed amount of time.",
    note: "Note: To enable this, bucket Versioning must be enabled.",
  },
  privateAccessFeature: {
    description:
      "Public access is granted to buckets and objects through access control lists (ACLs). In order to ensure that public access to this bucket and its objects is blocked, turn on Block all public access. These settings apply only to this bucket.",
  },
};

export const putRequestCommonHeaders: { [key: string]: string } = {
  "Content-Type": "application/xml",
};

export const TRUE = true;

export const FALSE = false;

export const HTTP_RESPONSE_CODE = {
  SUCCESS: 200,
  INVALID_CREDENTIALS: 401,
  PERMISSION_DENIED: 403,
  UNPROCESSABLE_ENTITY: 422,
  SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  METHOD_NOT_ALLOWED: 405,
  BAD_REQUEST: 400,
  CONFLICT: 409,
  NOT_FOUND: 404,
};

export const chunkSize = 100 * 1024 * 1024;

export const cidrRegex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))$/;

export const PORT_MIN_VALUE = 1;
export const PORT_MAX_VALUE = 65535;
export const ICMP_MIN_VALUE = -1;
export const ICMP_MAX_VALUE = 255;

export const kubernetesDeleteErrorMessage =
  "Unable to process your request. Try again!";
export const kubernetesGetErrorMessage = "Failed to fetch data!";
export const WrongProjectIdError = "Project not found or access denied";

export const TABLE_NULL_PLACEHOLDER = "---";

export const hideRegions = [
  "/ticket-dashboard",
  "/view-tickets",
  "/create-ticket",
  "/ticket/[id]",
];

export const desiredResourceOrder: string[] = [
  "volumes",
  "instances",
  "networks",
  "publicips",
  "floatingips",
  "routers",
  "snapshots",
  "images",
  "loadbalancers",
];

export const billingServices: Record<string, string> = {
  volumes: "Volume",
  instances: "Instance",
  networks: "Network",
  publicips: "Public IP",
  floatingips: "Floating IP",
  routers: "Router",
  snapshots: "Snapshot",
  images: "Custom Image",
  loadbalancers: "Load Balancer",
};

export const resourceTableHeader: { [key: string]: { columns: string[] } } = {
  instances: {
    columns: ["Instance Id", "Flavor Name", "Usage", "Amount"],
  },
  volumes: {
    columns: ["Volume Id", "Size", "Usage", "Amount"],
  },
  networks: {
    columns: ["Network Id", "Network Name", "Usage", "Amount"],
  },
  publicips: {
    columns: ["Hostname", "IP Address", "Usage", "Amount"],
  },
  floatingips: {
    columns: ["Hostname", "IP Address", "Usage", "Amount"],
  },
  snapshots: {
    columns: ["Snapshot Name", "Size", "Usage", "Amount"],
  },
  images: {
    columns: ["Image Name", "Size", "Usage", "Amount"],
  },
  routers: {
    columns: ["Router Name", "Router Id", "Usage", "Amount"],
  },
  loadbalancers: {
    columns: ["Load Balancer Name", "Load Balancer Id", "Usage", "Amount"],
  },
};

export const servicesWithHiddenResourcesTab = ["e2e", "openstack"];
export const RAM_DIVIDE_BY: number = 1024;
export const alphaNumericRegex = /^[a-zA-Z0-9\s\-_]+$/;
export const descriptionRegex = /^[a-zA-Z0-9_\-., ]*$/;
export const alphaNumericRegexWithoutSpace = /^[a-zA-Z0-9\-_]+$/;
export const volumeSourcesData = [
  {
    option_name: `No source, empty ${moduleNamesUtils.volume.version3 ?? "volume"}`,
    option_value: "none",
  },
  {
    option_name: `${moduleNamesUtils.image.version1 ?? "Images"}`,
    option_value: "image",
  },
  {
    option_name: `${moduleNamesUtils.volume.version1 ?? "Volume"}`,
    option_value: "volume",
  },
  {
    option_name: `${moduleNamesUtils.snapshot.version1 ?? "Snapshot"}`,
    option_value: "snapshot",
  },
];

export const imageDiskFormatData = [
  "Raw",
  "VDI - Virtual Disk Image",
  "VHD - Virtual Hard Disk",
  "VHDX - Large Virtual Hard Disk",
  "VMDK - Virtual Machine Disk",
  "QCOW2 - QEMU Emulator",
];

export const volumeTypeOptions = [
  {
    option_name: "NVMe based High IOPS Storage",
    option_value: "NVMe based High IOPS Storage",
  },
];

export const e2eVolumeSizeOptions = [
  { option_name: "250 GB", option_value: 250 },
  { option_name: "500 GB", option_value: 500 },
  { option_name: "1 TB", option_value: 1000 },
  { option_name: "2 TB", option_value: 2000 },
  { option_name: "4 TB", option_value: 4000 },
  { option_name: "8 TB", option_value: 8000 },
  { option_name: "16 TB", option_value: 16000 },
  { option_name: "24 TB", option_value: 24000 },
];

export const E2ESizeIopsMap: { [key: string]: string } = {
  default: "---",
  "250": "5000",
  "500": "10000",
  "1000": "20000",
  "2000": "40000",
  "4000": "80000",
  "8000": "120000",
  "16000": "240000",
  "24000": "360000",
};
export const backupSizeUnit = "GB";
export const OVERVIEW_NULL_PLACEHOLDER = "-";

export const protocolMapping: { [key: string]: string } = {
  "Custom TCP": "tcp",
  "Custom UDP": "udp",
  "Custom ICMP": "icmp",
};

export const scriptFileMaxSize = 16384;
export const editBackupApiVersion = "volume 3.9";
export const openstackVolumeAvailabilityZone = "nova";

export const minimumVolumeSize = 8;
export const minAdditionalVolumeSize = 2;
export const CIRCULAR_PROGRESS_STATUS = [
  "build",
  "deleting",
  "resize",
  "revert_resize",
  "rebuild",
  "reboot",
  "hard_reboot",
  "creating",
  "in process",
  "reinstalling",
];

export const INSTANCE_STATUS_WITH_SUCCESS_COLOR = [
  "build",
  "resize",
  "revert_resize",
  "creating",
  "in process",
];

export const instanceSecurityGroupState = { name: "", id: "", description: "" };
export const instanceKeyPairState = { name: "", publicKey: "", type: "" };

export const IPv4Ipv6CIDRRegex =
  /^(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[12]?[0-9]))|((([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:)?((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))\/(12[0-8]|1[01][0-9]|[1-9]?[0-9]))$/;

export const noidaServiceRegion = "ap-south-noi-1";
export const atlantaServiceRegion = "us-east-at-1";
export const fileFolderRegex = /^(?!.*[<>:"\/\\|?*%+&#]).+(?<!\s)$/;

export const s3Routes = [
  "/s3/policies",
  "/s3/users",
  "/s3/buckets",
  "/s3/buckets/[bucketName]",
  "/s3/buckets/[bucketName]/[...objectName]",
  "/s3/buckets/create",
  "/s3/buckets/[bucketName]/settings",
  "/s3/accessKey",
];

export const publiclyExposedIPLists: string[] = ["0.0.0.0/0", "::/0"];
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const defaultIamPolicies = ["User", "Admin"];
export const DEFAULT_IAM_GROUPS = ["Admins", "Users"];
export const routeWithoutKycAndProjects = [
  "/dashboard",
  "/ticket-dashboard",
  "/view-tickets",
  "/create-ticket",
  "/ticket/[id]",
];
export const accountTypeWithoutKyc = ["internal"];
export const strongPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
export const reactQueryMaxRetryAttempts: number = 1;
export const spotInstanceFlavorNameRegex: string = "-spot";
export const pagesWithoutContext = ["/login", "/register"];

export const IPIFY_URL = "https://api.ipify.org?format=json";

export const regionDisplayNameMapping: { [key: string]: string } = {
  "ap-south-mum-1": "Mumbai",
  "ap-south-noi-1": "Noida",
  "us-east-at-1": "Atlanta",
  "ap-south-del-1": "Delhi",
};

export const allowedInsightsRegions = [
  "ap-south-mum-1",
  "ap-south-noi-1",
  "us-east-at-1",
];
export const fourDigitsAfterDecimalRegex = /^\d+(\.\d{1,4})?$/;
