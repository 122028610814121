import { NextApiRequest } from "next/types";
import { ServiceProviderConfigType } from "src/types/S3/service-provider-config";
import {
  INVALID_REGION_RESPONSE,
  REGION_REQUIRED_RESPONSE,
} from "src/language/en/message";
import { CommonPrefix } from "@aws-sdk/client-s3";
import { ContentType } from "src/types/S3/FileType";
import { customDecodeURIComponent } from "src/configs/helper";
import {
  cephS3ServiceConfig,
  regionProviderMapping,
  activeScaleS3ServiceConfig,
  allowedPolicyConditions,
  allowedPolicyConditionKeys,
  allowedPolicyActions,
} from "src/utils/S3/S3Constants";

interface AccessOutput {
  [key: string]: Array<"Read" | "Write">;
}

export const getAccessKeyFromRequest = (request: NextApiRequest): string => {
  let S3AccessKey = request.headers["x-custom-access-key"];
  return S3AccessKey ? decodeURIComponent(S3AccessKey as string) : "";
};

export const getSecretKeyFromRequest = (request: NextApiRequest): string => {
  let S3SecretKey = request.headers["x-custom-secret-key"];
  return S3SecretKey ? decodeURIComponent(S3SecretKey as string) : "";
};

export const getS3ServiceProviderConfig = (
  region: string,
): ServiceProviderConfigType => {
  try {
    if (!region) {
      throw new Error(REGION_REQUIRED_RESPONSE);
    }
    const provider = regionProviderMapping[region];
    if (!provider) {
      throw new Error(INVALID_REGION_RESPONSE);
    }
    let S3ServiceConfig = undefined;
    switch (provider) {
      case "ceph": {
        S3ServiceConfig = cephS3ServiceConfig;
        break;
      }
      case "activescale": {
        S3ServiceConfig = activeScaleS3ServiceConfig;
        break;
      }
      default: {
        throw new Error(INVALID_REGION_RESPONSE);
      }
    }
    return S3ServiceConfig;
  } catch (error) {
    throw error;
  }
};

export const percentEncode = (input: string): string => {
  return input
    ? encodeURIComponent(decodeURIComponent(input))
        .replace(
          /[!'()*]/g,
          (char) => "%" + char.charCodeAt(0).toString(16).toUpperCase(),
        )
        .replace(
          /[-.~]/g,
          (char) => "%" + char.charCodeAt(0).toString(16).toUpperCase(),
        )
        .replace(/"/g, (char) => "%22")
        .replace(/'/g, (char) => "%27")
        .replace(/`/g, (char) => "%60")
    : input;
};

export const percentDecode = (input: string): string => {
  if (!input) {
    return input;
  }

  return decodeURIComponent(input.replace(/%(?![0-9a-fA-F]{2})/g, "%25"))
    .replace(/\+/g, " ")
    .replace(/%27/g, "'")
    .replace(/%22/g, '"')
    .replace(/%60/g, "`");
};

export const isOnLastPage = (
  pagination: { pageSize: number; pageIndex: number },
  objectListLength: number,
): boolean => {
  if (!pagination.pageSize) {
    return false;
  }
  const totalPages = Math.ceil(objectListLength / pagination.pageSize);
  return pagination.pageIndex === totalPages - 1;
};

export const appendCommonPrefixes = (
  contents: ContentType[],
  commonPrefix: CommonPrefix,
  isVersioning: boolean,
): ContentType[] => {
  if (!commonPrefix?.Prefix) {
    return contents;
  } else {
    const commonPrefixObject: ContentType = {
      Key: commonPrefix.Prefix,
      LastModified: "",
      ETag: "",
      Size: 0,
      StorageClass: "",
    };
    if (isVersioning) {
      commonPrefixObject["VersionId"] = "";
    }
    return [commonPrefixObject, ...contents];
  }
};

export const decodeKeyFileName = (key: string, prefixString: string) => {
  let decodedKey = customDecodeURIComponent(key.replace(/\+/g, " "));
  if (decodedKey.startsWith(prefixString)) {
    decodedKey = decodedKey.substring(prefixString.length);
  }
  return customDecodeURIComponent(decodedKey);
};

export const getBucketPolicySchema = (bucketName: string) => {
  return {
    type: "object",
    properties: {
      Version: { type: "string" },
      Id: { type: "string" },
      Statement: {
        type: "array",
        items: {
          type: "object",
          properties: {
            Sid: { type: "string" },
            Effect: { type: "string", enum: ["Allow", "Deny"] },
            Action: {
              oneOf: [
                {
                  type: "string", // Single action
                  pattern: "^(\\*|[^,]+)$", // Allow "*" or any other string without commas
                  errorMessage: "Action cannot be a comma-separated string",
                  enum: [...allowedPolicyActions, "*"], // Include "*" in the allowed actions
                },
                {
                  type: "array", // Array of actions
                  items: {
                    type: "string",
                    pattern: "^(\\*|[^,]+)$", // Allow "*" or any string without commas
                    errorMessage: "Each action cannot contain commas",
                    enum: [...allowedPolicyActions, "*"], // Include "*" in allowed actions
                    uniqueItems: true, // Ensure no duplicate actions
                  },
                },
              ],
            },
            Resource: {
              oneOf: [
                {
                  type: "string",
                  // Allow * or ARN pattern with bucket name and support folder structure and file extensions
                  pattern: `^(\\*|arn:aws:s3:::${bucketName}(?:/[a-zA-Z0-9-_.\\/]*\\*?)?)$`,
                  errorMessage: `Resource must be either '*' or 'arn:aws:s3:::${bucketName}/*' and allow subfolders and files with extensions.`,
                },
                {
                  type: "array",
                  items: {
                    type: "string",
                    // Same validation for array items
                    pattern: `^(\\*|arn:aws:s3:::${bucketName}(?:/[a-zA-Z0-9-_.\\/]*\\*?)?)$`,
                    errorMessage: `Each Resource item must be either '*' or 'arn:aws:s3:::${bucketName}/*' and allow subfolders and files with extensions.`,
                  },
                  uniqueItems: true,
                },
              ],
            },
            Principal: {
              oneOf: [
                {
                  type: "string",
                  enum: ["*"], // Allow "*" as a valid string value
                  errorMessage: "Principal must be '*' or a valid AWS account",
                },
                {
                  type: "object",
                  properties: {
                    AWS: {
                      oneOf: [
                        {
                          type: "string",
                          minLength: 1, // Ensure AWS has some value as a string
                          errorMessage:
                            "AWS account or ARN must be a non-empty string",
                        },
                        {
                          type: "array",
                          items: {
                            type: "string",
                            minLength: 1, // Ensure each AWS account or ARN is a non-empty string
                            errorMessage:
                              "Each AWS account or ARN must be a non-empty string",
                          },
                          uniqueItems: true, // Ensure no duplicate ARNs/accounts in the array
                        },
                      ],
                      errorMessage:
                        "AWS must be a non-empty string or an array of non-empty strings",
                    },
                  },
                  required: ["AWS"], // Require the AWS field in the object
                  additionalProperties: false,
                },
              ],
              errorMessage: "Principal cannot be an empty string or object",
            },
            Condition: {
              type: "object",
              patternProperties: {
                [`^(${allowedPolicyConditions.join("|")})`]: {
                  type: "object",
                  patternProperties: {
                    [`^(${allowedPolicyConditionKeys.join("|")})$`]: {
                      oneOf: [
                        {
                          type: "string", // Single action
                          pattern: "^(\\*|[^,]+)$", // Allow "*" or any other string without commas
                          errorMessage:
                            "Action cannot be a comma-separated string",
                        },
                        {
                          type: "array", // Array of actions
                          items: {
                            type: "string",
                            pattern: "^(\\*|[^,]+)$", // Allow "*" or any string without commas
                            errorMessage: "Each action cannot contain commas",
                            uniqueItems: true, // Ensure no duplicate actions
                          },
                        },
                      ],
                    },
                  },
                  additionalProperties: false,
                },
              },
              additionalProperties: false,
            },
          },
          required: ["Sid", "Effect", "Action", "Resource", "Principal"],
          additionalProperties: false,
        },
      },
    },
    required: ["Version", "Statement"],
    additionalProperties: false,
  };
};
export const getObjectType: (objectName: string) => string = (objectName) => {
  return objectName.includes("/") ? "folder" : "file";
};
